'use strict'

$(document).ready(function() {
    StartEvents();
});

function StartEvents(){
    // Configuración para cada input
    var inputs = [
        { id: '#filter_passengerCapacity', min: 0, max: 20},
        { id: '#filter_bagCapacity', min: 1, max: 5},
        { id: '#filter_noOfDoors', min: 2, max: 5}
    ];

    // Aplicar la lógica a cada input
    inputs.forEach(function(input) {
        handleNumericInput($(input.id), input.min, input.max);
    });
}

document.addEventListener('DOMContentLoaded', function(e){

    $("#dateRangeSearchCars").daterangepicker({
        timePicker: true,
        timePicker24Hour: true,
        timePickerIncrement: 15,
        locale: {
            format: "DD-MM-YYYY HH:mm",
        }
    });
    
    $('#dateRangeSearchCars').on('cancel.daterangepicker', function(ev, picker) {
    //do something, like clearing an input
        $("#dateRangeSearchCars").val("");
    });

});

//MÉTODOS

$("#form-search-cars").submit(function (event) {
    loadingGifFunction();
});

// Función genérica para manejar los inputs numéricos
function handleNumericInput(element, min, max) {
    element.on('input', function() {
        var value = parseInt($(this).val());
        if (isNaN(value)) {
            $(this).val('');
        } else if (value < min) {
            $(this).val(min.toString());
        } else if (value > max) {
            $(this).val(max.toString());
        } else if (element.attr('id') === 'filter_noOfDoors') {
            $(this).val(Math.floor(value));
        }
    });
}



